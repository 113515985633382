<script setup lang="ts">
const localePath = useLocalePath()
const drawers = useDrawers()
const priceFormatter = usePriceFormatter()
const { t, locales, locale } = useI18n()
const router = useRouter()

const { activeOrder } = useActiveOrder()

async function handleClose() {
  drawers.value.cart.open = false
  await nextTick()
}

async function redirectToCheckout() {
  await handleClose()
  await router.push(localePath('checkout'))
}

const localeCurrency = computed(() => {
  return locales.value.find(l => l.code === locale.value)?.currency
})

const prizeMoney = computed(() => {
  return priceFormatter(+useRuntimeConfig().public.prizeMoney[localeCurrency.value] * 100, localeCurrency.value)
})

const showCart = computed(() => drawers.value.cart.open)
</script>

<template>
  <NDrawer id="cart-drawer" slide-from="right" :show="showCart" :title="t('cart.label')" closable @close="handleClose">
    <div class="mt-8">
      <template v-if="activeOrder?.totalQuantity">
        <div class="flow-root">
          <ul role="list" class="-my-6 divide-y divide-slate-200">
            <CartLineItem
              v-for="line in activeOrder?.lines"
              :key="line.id"
              :line="line"
              :currency="activeOrder?.currency"
              class="px4 py6 sm:px6"
              @click:link="handleClose"
            />
          </ul>
        </div>
      </template>

      <div v-else class="h-48 flex items-center justify-center text-xl text-slate-400">
        {{ t('cart.empty.heading') }}
      </div>
    </div>

    <template v-if="activeOrder?.totalQuantity" #footer>
      <div class="pb-safe">
        <div class="hidden bg-amber-200:35 px4 py2 sm:flex sm:items-center sm:px6">
          <NIcon icon="i-ri:timer-flash-line" n="slate8 xl" class="mr3 text-context -ml1" />
          <span class="text-ce text-sm c-slate-800 fw600">
            {{ t('cart.items_not_reserved') }}
          </span>
        </div>

        <div class="pb6">
          <div class="hidden sm:block divide-y n-divide-base">
            <ProductFeature
              icon="i-heroicons:gift-top"
              :text="t('promotion.purchase_competition_prizes', [prizeMoney])"
              n="orange5"
              class="px4 py2 sm:px6"
            />
          </div>

          <NTinyCarousel :items="2" class="h18 text-white sm:hidden">
            <template #slide-0>
              <ProductFeature
                icon="i-heroicons:gift-top"
                :text="t('promotion.purchase_competition_prizes', [prizeMoney])"
                n="orange5"
                class="px4 py2 sm:px6"
              />
            </template>
          </NTinyCarousel>

          <div class="border-t n-border-base">
            <div class="flex justify-between px4 py0.5 pt4 text-base text-slate-900 sm:px6">
              <p class="text-sm" v-text="t('product.free_shipping.label')" />
              <div><NIcon icon="i-ri:check-fill" n="emerald5 lg" class="text-context" /></div>
            </div>

            <div class="flex justify-between px4 py0.5 text-base text-slate-900 sm:px6">
              <p class="text-sm" v-text="t('order.free_gift')" />
              <div><NIcon icon="i-ri:check-fill" n="emerald5 lg" class="text-context" /></div>
            </div>

            <div class="flex justify-between px4 py0.5 text-base text-slate-900 sm:px6">
              <p class="text-sm" v-text="t('order.hassle_free_complaint_resolution')" />
              <div><NIcon icon="i-ri:check-fill" n="emerald5 lg" class="text-context" /></div>
            </div>

            <div class="flex justify-between px4 pt2 text-base text-slate-900 fw600 sm:px6">
              <p v-text="t('order.summary.subtotal.label')" />
              <p v-if="activeOrder.subTotal">
                {{ priceFormatter(activeOrder.subTotal, activeOrder.currency) }}
              </p>
            </div>
          </div>

          <div class="mt-6 px4 sm:px6">
            <NButton
              id="checkout"
              n="emerald5 solid"
              class="w-full justify-center rounded-full fw600 leading-10"
              @click="redirectToCheckout"
            >
              {{ t('checkout.label') }}
            </NButton>
          </div>
        </div>
      </div>
    </template>
  </NDrawer>
</template>
